import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
    Grid,
    Paper,
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableSortLabel,
    TableHead,
    TableRow,
    Box,
    Collapse,
    IconButton,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { formatter } from '../../../utils/formatter';
import {
    BoxContainer,
    Button,
    CircularProgress,
    CollapsibleRowHeader,
    CodeBlock,
    FormControl,
    TextField,
} from '../styled';
import { RecurringTransaction, RecurringCategory, RecurringItem } from '../types';
import { SpendingMonitorQuery } from '../FetchSpend.query.v2.generated';

export interface RecurringData {
    transactions?: RecurringTransaction[];
}

interface DataProps {
    claims: any;
    processing?: boolean;
    // apiStatus?: number;
    spendingData?: SpendingMonitorQuery;
    onSubmit: (userId: string) => Promise<RecurringData>;
}

const SpendingTable = ({ spending }: { spending: SpendingMonitorQuery }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Balances Type</TableCell>
                        <TableCell>Limit</TableCell>
                        <TableCell>Current</TableCell>
                        <TableCell>Available</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Assets</TableCell>
                        <TableCell>{null}</TableCell>
                        <TableCell>{formatter.format(spending.balances.assets.current)}</TableCell>
                        <TableCell>
                            {formatter.format(spending.balances.assets.available)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Credit</TableCell>
                        <TableCell>{formatter.format(spending.balances.credit.limit)}</TableCell>
                        <TableCell>{formatter.format(spending.balances.credit.current)}</TableCell>
                        <TableCell>
                            {formatter.format(spending.balances.credit.available)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Liabilities</TableCell>
                        <TableCell>
                            {formatter.format(spending.balances.liabilities.limit)}
                        </TableCell>
                        <TableCell>
                            {formatter.format(spending.balances.liabilities.current)}
                        </TableCell>
                        <TableCell>
                            {formatter.format(spending.balances.liabilities.available)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Projected Type</TableCell>
                        <TableCell>Cash</TableCell>
                        <TableCell>Income</TableCell>
                        <TableCell>Spent</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Next Week</TableCell>
                        <TableCell>
                            {formatter.format(spending.projectedBalances.week.cash)}
                        </TableCell>
                        <TableCell>
                            {formatter.format(spending.projectedBalances.week.income)}
                        </TableCell>
                        <TableCell>
                            {formatter.format(spending.projectedBalances.week.spent)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Next Month</TableCell>
                        <TableCell>
                            {formatter.format(spending.projectedBalances.month.cash)}
                        </TableCell>
                        <TableCell>
                            {formatter.format(spending.projectedBalances.month.income)}
                        </TableCell>
                        <TableCell>
                            {formatter.format(spending.projectedBalances.month.spent)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

interface RecurringTransactionRowData {
    memo: string;
    transactionsCount: number;
    interval: string;
    confidence: number;
    debug: string;
    averageGapDays: number;
    amount: number;
    nextBillDate: string;
    lastTransactedAt: string;
    category: string;
}

interface RecurringTransactionRowTransactions extends RecurringTransactionRowData {
    transactions: RecurringItem[];
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof RecurringTransactionRowData;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'memo',
        numeric: false,
        disablePadding: true,
        label: 'Memo',
    },
    {
        id: 'transactionsCount',
        numeric: true,
        disablePadding: false,
        label: 'Transactions Count',
    },
    {
        id: 'interval',
        numeric: false,
        disablePadding: false,
        label: 'Interval',
    },
    {
        id: 'confidence',
        numeric: true,
        disablePadding: false,
        label: 'Interval Confidence',
    },
    {
        id: 'debug',
        numeric: false,
        disablePadding: false,
        label: 'Interval Debug',
    },
    {
        id: 'averageGapDays',
        numeric: true,
        disablePadding: false,
        label: 'Interval Average Gap Days',
    },
    {
        id: 'amount',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'nextBillDate',
        numeric: false,
        disablePadding: false,
        label: 'Next Bill Date',
    },
    {
        id: 'lastTransactedAt',
        numeric: false,
        disablePadding: false,
        label: 'Last Transacted At',
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof RecurringTransactionRowData
    ) => void;
    order: Order;
    orderBy: string;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof RecurringTransactionRowData) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span">{order === 'desc' ? '' : ''}</Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
const Row = ({ row }: { row: RecurringTransactionRowTransactions }) => {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <CollapsibleRowHeader>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.memo}</TableCell>
                <TableCell align="right">{row.transactionsCount}</TableCell>
                <TableCell align="right">{row.interval}</TableCell>
                <TableCell align="right">{Number(row.confidence).toFixed(3)}</TableCell>
                <TableCell padding="none" height="50" size="small" align="right">
                    {row.debug}
                </TableCell>
                <TableCell align="right">{Number(row.averageGapDays).toFixed(3)}</TableCell>
                <TableCell align="right">{formatter.format(row.amount)}</TableCell>
                <TableCell align="right">
                    <span style={{ whiteSpace: 'nowrap' }}>{row.nextBillDate}</span>
                </TableCell>
                <TableCell align="right">
                    <span style={{ whiteSpace: 'nowrap' }}>{row.lastTransactedAt}</span>
                </TableCell>
            </CollapsibleRowHeader>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Transactions
                            </Typography>
                            <CodeBlock>
                                <pre>{JSON.stringify(row.category, null, 2)}</pre>
                            </CodeBlock>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Date Diff</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.transactions.map((txn, idx) => (
                                        <TableRow key={txn.transactedAt}>
                                            <TableCell component="th" scope="row">
                                                {txn.transactedAt}
                                            </TableCell>
                                            <TableCell>
                                                {idx > 0
                                                    ? moment(
                                                          row.transactions[idx - 1].transactedAt
                                                      ).diff(moment(txn.transactedAt), 'days')
                                                    : 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {formatter.format(row.amount)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const TransactionsTable = ({ transactions }: { transactions?: RecurringTransaction[] }) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof RecurringTransactionRowData>('confidence');
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof RecurringTransactionRowData
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const rows = (transactions ?? []).map(t => {
        const row: RecurringTransactionRowTransactions = {
            memo: t.memo,
            interval: t.intervalResult?.type,
            transactionsCount: t.transactions.length,
            confidence: t.intervalResult?.confidence ?? 0.0,
            debug: t.intervalResult?.debug,
            averageGapDays: t.intervalResult?.averageGapDays ?? 0.0,
            amount: t.value,
            nextBillDate: t.nextBillDate ?? '',
            lastTransactedAt: t.lastTransactedAt,
            category: t.category?.primary ?? '',
            transactions: t.transactions,
        };
        return row;
    });
    const sortedRows = React.useMemo(() => {
        return rows.sort(getComparator(order, orderBy));
    }, [transactions, order, orderBy, rows]);
    return (
        <TableContainer component={Paper}>
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {sortedRows.map(row => {
                        return (
                            <Row
                                key={`${row.memo} ${row.transactions[0].transactedAt}`}
                                row={row}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ({ onSubmit, processing, spendingData, claims }: DataProps) => {
    const [userId, setUserId] = useState('');
    const [data, setData] = useState<RecurringData>({});
    const resetForm = useCallback(() => {
        setUserId('');
        const clearButtons = document.getElementsByClassName(
            'MuiAutocomplete-clearIndicator'
        ) as unknown as HTMLButtonElement[];
        clearButtons[0].click();
        clearButtons[1].click();
    }, []);
    const showBalances = claims.int?.includes('cda');
    const submit = useCallback(async () => {
        if (userId) {
            const result = await onSubmit(userId);
            if (result) {
                setData(result);
            }
        }
    }, [userId, resetForm]);
    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Recurring Transactions</title>
            </Helmet>

            <BoxContainer container spacing={3}>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper elevation={1}>
                        <Container>
                            <Typography variant="h6">Get Recurring</Typography>
                            <FormControl variant="outlined">
                                <TextField
                                    onChange={e => setUserId(e.target.value)}
                                    variant="outlined"
                                    disabled={processing}
                                    placeholder="User ID"
                                    size="small"
                                    value={userId}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submit}
                                    disabled={!userId}
                                >
                                    {processing ? <CircularProgress color="inherit" /> : 'Submit'}
                                </Button>
                            </FormControl>
                            {spendingData && showBalances && (
                                <SpendingTable spending={spendingData} />
                            )}
                            {showBalances && (
                                <>
                                    <Typography variant="h6">Income</Typography>
                                    <TransactionsTable
                                        transactions={data.transactions?.filter(
                                            t => t.value > 0 && t.transactions.length > 2
                                        )}
                                    />
                                </>
                            )}
                            <Typography variant="h6">Spending</Typography>
                            <TransactionsTable
                                transactions={data.transactions?.filter(
                                    t => t.value <= 0 && t.transactions.length > 2
                                )}
                            />
                        </Container>
                    </Paper>
                </Grid>
            </BoxContainer>
        </>
    );
};
